import { defineStore } from 'pinia'

import type { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios'
import { UtilMethods } from '@/plugins/util'

// Simple key/value list of named requests matched to their corresponding
// promise as a mechanism to wait for their completion from multiple potential
// listeners
interface RequestWaitList {
  // Disable the limitation on an explicit "any" type for this, since "any" is
  // what Axios will return and it allows for less downstream boilerplating
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: Promise<any>
}

// Simple key/value list that can be used to represent a generic request body
// object for Axios requests
export interface BodyData {
  [key: string]: unknown
}

export const useHelperStore = defineStore('helper', {
  state: () => ({
    activeFetchList: {} as RequestWaitList
  }),
  actions: {
    /**
     * Combines values in two arrays based on the specified shared property.
     *
     * @param base Array with current values to potentially override with newer.
     * @param add Array with new values to potentially use for override.
     * @param prop The name of the property to join the arrays on.
     * @returns Promise of a new, merged array.
     */
    // Allow "any" type since the function needs to be generic
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergeArrays: function (base: Array<any>, add: Array<any>, prop: string) {
      // Get the values in our current list who are not in the new list
      const baseUnique = base.filter((f) => (add.find((s) => f[prop] === s[prop])) === undefined)

      // Combine this list with the new list to make a final list, assuming that
      // the new values will override anything that would have matched in the
      // current list
      return baseUnique.concat(add)
    },
    /**
     * GET data from a specified API path, handling logic to avoid duplication
     * if there are concurrent requests for the same path.
     *
     * @param axios The fully configured Axios object to make requests.
     * @param path The API path to request.
     * @param config Optional extra configuration for the Axios request.
     * @returns Promise of the API response.
     */
    fetchPath: function (axios: AxiosStatic, path: string, config?: AxiosRequestConfig) {
      // Check if we're already fetching this item and return the existing
      // request's promise if we are
      if (this.activeFetchList[path] !== undefined) {
        return this.activeFetchList[path]
      }

      // Indicate that we're working on this request
      this.activeFetchList[path] = new Promise((resolve, reject) => {
        axios.get(path, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch(reject)
          .finally(() => {
            // Clean up our promise record no matter what
            delete this.activeFetchList[path]
          })
      })

      // Return the promise so the caller knows when we're finished
      return this.activeFetchList[path]
    },
    /**
     * Wrapper to handle common logic surrounding the result of fetchPath().
     *
     * @param axios The fully configured Axios object to make requests.
     * @param path The API path to request.
     * @param callback Method to call before resolving the promise, given the
     * values that were returned by the request.
     * @param config Optional extra configuration for the Axios request.
     * @returns Promise of nothing.
     */
    // Allow explicit any since that's what Axios will return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFetchPathPromise: function (axios: AxiosStatic, path: string, callback: (values: any) => void, config?: AxiosRequestConfig): Promise<void> {
      return new Promise((resolve, reject) => {
        this.fetchPath(axios, path, config)
          .then((values) => {
            callback(values)
            resolve()
          })
          .catch(reject)
      })
    },
    /**
     * Convenience method to perform an action after any active fetch for a
     * given path has completed, without caring about the values returned by the
     * fetch or initiating a new fetch if there wasn't already one processing.
     *
     * @param path The API path to look for a fetch of. If a string, it will be
     * used directly against the active fetch list. If it is a RegExp, it will
     * be matched against all potential keys in the active fetch list.
     * @param callback Method to call when the fetch is complete or if there is
     * no fetch.
     */
    waitFetchPathPromise: function (path: string|RegExp, callback: () => void): void {
      if (typeof path === 'string') {
        const p = this.activeFetchList[path]
        if (p !== undefined) {
          p.finally(() => {
            callback()
          })
        } else {
          callback()
        }
      } else {
        const p = []
        for (const key of Object.keys(this.activeFetchList)) {
          if (path.test(key)) {
            p.push(this.activeFetchList[key])
          }
        }
        Promise.allSettled(p)
          .finally(() => {
            callback()
          })
      }
    },
    /**
     * POST or PUT data to a specified API path, handling logic to add or update
     * the store's list of the corresponding object based on the changes.
     *
     * @param axios The fully configured Axios object to make requests.
     * @param data The POST or PUT request body data.
     * @param postUrl The URL to make a POST request, if appropriate.
     * @param putUrl The URL to make a PUT request, if appropriate.
     * @param repository The calling store's list of the corresponding object.
     * @param postFields List of fields to be stored from a POST request.
     * @param putFields List of fields to be stored from a PUT request.
     * @returns Promise of the modified object's ID.
     */
    // Allow explicit any since we need to be able to set properties for
    // repository objects, and we can't do that if they're unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    upsertObject: function (axios: AxiosStatic, data: BodyData, postUrl: string, putUrl: string, repository: Array<any>, postFields: Array<string>, putFields: Array<string>): Promise<number> {
      // Disable the limitation on an explicit "any" type for this, since "any"
      // is what Axios will return
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let req: Promise<AxiosResponse<any>>
      let isNew = false
      if (data.id !== undefined && data.id !== null && data.id !== '') {
        req = axios.put(putUrl, data)
      } else {
        isNew = true
        req = axios.post(postUrl, data)
      }

      // Create a promise that should resolve to our object ID when finished
      return new Promise((resolve, reject) => {
        req.then((res) => {
          // Update our repository list for this object
          if (isNew === false) {
            // Update the target fields if we have a record of this object
            // already, always including the generic updated_at field
            const match = repository.find((f) => f.id === data.id)
            if (match !== undefined) {
              for (const prop of putFields) {
                match[prop] = data[prop]
              }
              match.capabilities = data.capabilities
              match.updated_at = UtilMethods.getApiDateTimeString()
            }
          } else {
            // Add the target fields as a new object, always including the
            // generic created_at field
            const newObject: BodyData = {}
            for (const prop of postFields) {
              if (prop === 'id') {
                newObject[prop] = parseInt(res.headers.location)
              } else {
                newObject[prop] = data[prop]
              }
            }
            newObject.capabilities = data.capabilities
            newObject.created_at = UtilMethods.getApiDateTimeString()
            repository.push(newObject)
          }

          // Resolve to the ID of the object
          resolve(data.id || res.headers.location)
        })
          .catch(reject)
      })
    },
    /**
     * Convenience method to call upsert for only an insert.
     *
     * @param axios The fully configured Axios object to make requests.
     * @param data The POST request body data.
     * @param url The URL to make a POST request.
     * @param repository The calling store's list of the corresponding object.
     * @param fields List of fields to be stored from a POST request.
     * @returns Promise of the new object's ID.
     */
    // Allow explicit any since we need to be able to set properties for
    // repository objects, and we can't do that if they're unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addObject: function (axios: AxiosStatic, data: BodyData, url: string, repository: Array<any>, fields: Array<string>): Promise<number> {
      return this.upsertObject(axios, data, url, '', repository, fields, [])
    },
    /**
     * Convenience method to call upsert for only an update.
     *
     * @param axios The fully configured Axios object to make requests.
     * @param data The PUT request body data.
     * @param url The URL to make a PUT request.
     * @param repository The calling store's list of the corresponding object.
     * @param fields List of fields to be stored from a PUT request.
     * @returns Promise of the modified object's ID.
     */
    // Allow explicit any since we need to be able to set properties for
    // repository objects, and we can't do that if they're unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateObject: function (axios: AxiosStatic, data: BodyData, url: string, repository: Array<any>, fields: Array<string>): Promise<number> {
      return this.upsertObject(axios, data, '', url, repository, [], fields)
    },
    /**
     * DELETE an object through an API path, handling logic to remove the object
     * from the store's list of objects.
     *
     * @param axios The fully configured Axios object to make requests.
     * @param id The ID of the object to remove.
     * @param url The URL to make a DELETE request.
     * @param repository The calling store's list of the corresponding object.
     * @param alternateAction Method to call instead of removing the object from
     * the repository on completion.
     * @returns Promise of nothing, resolved when the removal is complete.
     */
    // Allow explicit any since we need to be able to access the ID of
    // repository objects, and we can't do that if they're unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeObject: function (axios: AxiosStatic, id: number, url: string, repository: Array<any>, alternateAction?: (deleted: any) => void): Promise<void> {
      const req = axios.delete(url)
      return new Promise((resolve, reject) => {
        req.then(() => {
          // Remove the object from our repository list or call our alternate
          // action method
          const i = repository.findIndex((f) => f.id === id)
          if (i !== -1) {
            if (alternateAction === undefined) {
              repository.splice(i, 1)
            } else {
              alternateAction(repository[i])
            }
          }

          // Complete our promise
          resolve()
        })
          .catch(reject)
      })
    }
  }
})
