// Object/permission values
export enum ObjectTypeId {
    ObjectType = 1000,
    User = 1001,
    AuthLocal = 1002,
    AuthOAuth2 = 1003,
    AuthLog = 1006,
    ScheduledJob = 1007,
    ScheduledJobExecution = 1008,
    Permission = 2001,
    RoleContext = 2002,
    RoleTemplate = 2003,
    RoleTemplatePermission = 2004,
    Role = 2005,
    RolePermission = 2006,
    UserRole = 2007,
    UserRoleApproval = 2008,
    SubscriptionScope = 3001,
    SubscriptionBillingMethod = 3002,
    SubscriptionBillingTerm = 3003,
    SubscriptionPricingDefinition = 3004,
    SubscriptionProductDefinition = 3005,
    Subscription = 3006,
    SubscriptionHold = 3007,
    SubscriptionPricing = 3008,
    Invoice = 3100,
    InvoiceLineItem = 3101,
    InvoicePayment = 3102,
    OrganizationType = 4001,
    Organization = 4002,
    OrganizationApproval = 4003,
    Notification = 5001,
    NotificationCompletion = 5002,
    LessonSet = 22000,
    Lesson = 22001,
    LessonVerseText = 22002,
    LessonSetLesson = 22003,
    LessonQuestion = 22004,
    LessonQuestionChoice = 22005,
    LessonCompletion = 22006,
    LessonComment = 22007,
    LessonSetSequence = 22008,
    LessonSetSequenceItem = 22009,
    LessonCommentApproval = 22010,
    CampSession = 23000,
    CampSessionChurch = 23001,
    CampSessionChurchApproval = 23002
}

// Role values
export enum RoleContextId {
    Global = 1,
    Self = 2,
    Organization = 3,
    CampSession = 201,
    CampSessionChurch = 202
}

export enum RoleTemplateId {
    GlobalAdmin = -1,
    SelfUser = -2,
    OrganizationAdmin = 1,
    CampSessionCampCoordinator = 201,
    CampSessionChurchCoordinator = 202,
    Camper = 203
}

// Organization values
export enum OrganizationTypeId {
    Generic = 1,
    Church = 101,
    Camp = 201
}

// Subscription values
export enum SubscriptionScopeId {
    User = 1,
    Organization = 2
}

// Bible values
export enum BibleVersionId {
    KJV = 1
}
