import { defineStore } from 'pinia'
import { useHelperStore } from './helper'

import * as PwaApi from '#/pwa-api'
import type { BodyData } from './helper'
import type { AxiosStatic } from 'axios'

export const useSubscriptionsStore = defineStore('subscriptions', {
  state: () => ({
    helperStore: useHelperStore(),

    subscriptionBillingMethods: [] as Array<PwaApi.SubscriptionBillingMethod>,
    subscriptionBillingTerms: [] as Array<PwaApi.SubscriptionBillingTerms>,
    subscriptionPricingDefinitions: [] as Array<PwaApi.SubscriptionPricingDefinition>,
    subscriptionScopes: [] as Array<PwaApi.SubscriptionScope>,
    subscriptions: [] as Array<PwaApi.Subscription>
  }),
  actions: {
    clear: function () {
      this.helperStore.waitFetchPathPromise('/subscriptions/billing/methods', () => { this.subscriptionBillingMethods = [] })
      this.helperStore.waitFetchPathPromise('/subscriptions/billing/terms', () => { this.subscriptionBillingTerms = [] })
      this.helperStore.waitFetchPathPromise('/subscriptions/pricing/definitions', () => { this.subscriptionPricingDefinitions = [] })
      this.helperStore.waitFetchPathPromise('/subscriptions/scopes', () => { this.subscriptionScopes = [] })
      this.helperStore.waitFetchPathPromise('/subscriptions', () => { this.subscriptions = [] })
    },
    fetchSubscriptionBillingMethods: function (axios: AxiosStatic): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/subscriptions/billing/methods', (values) => {
        this.subscriptionBillingMethods = values
      })
    },
    fetchSubscriptionBillingTerms: function (axios: AxiosStatic): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/subscriptions/billing/terms', (values) => {
        this.subscriptionBillingTerms = values
      })
    },
    fetchSubscriptionPricingDefinitions: function (axios: AxiosStatic): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/subscriptions/pricing/definitions', (values) => {
        this.subscriptionPricingDefinitions = values
      })
    },
    updateSubscriptionPricingDefinition: function (axios: AxiosStatic, data: BodyData): Promise<number> {
      return this.helperStore.updateObject(
        axios,
        data,
        `/subscriptions/pricing/definitions/${data.id}`,
        this.subscriptionPricingDefinitions,
        ['price', 'billing_cycles', 'billing_terms_id']
      )
    },
    fetchSubscriptionScopes: function (axios: AxiosStatic): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/subscriptions/scopes', (values) => {
        this.subscriptionScopes = values
      })
    },
    fetchSubscriptions: function (axios: AxiosStatic, ignore401 = false): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/subscriptions', (values) => {
        this.subscriptions = values
      }, {
        data: {
          ignore401
        }
      })
    },
    upsertSubscription: function (axios: AxiosStatic, data: BodyData): Promise<number> {
      return this.helperStore.upsertObject(
        axios,
        data,
        '/subscriptions',
        `/subscriptions/${data.id}`,
        this.subscriptions,
        ['id', 'subscription_scope_id', 'subscription_scope_object_id', 'start_date', 'end_date', 'pricing_definition_id', 'billing_method_id'],
        ['start_date', 'end_date', 'pricing_definition_id', 'billing_method_id']
      )
    },
    removeSubscription: function (axios: AxiosStatic, id: number) {
      return this.helperStore.removeObject(axios, id, `/subscriptions/${id}`, this.subscriptions, (deleted: PwaApi.Subscription) => {
        // Set some provisional date values to update our display to match
        // what we expect the API will say the next time the data is refreshed
        const now = new Date()
        deleted.cancel_date = now
        deleted.terminal_date = deleted.end_date
      })
    }
  }
})
