import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#221e3a',
        secondary: '#ab1425',
        accent: '#ed792b',
        success: '#ab1425', // Secondary
        error: '#ab1425' // Secondary
      },
      dark: {
        primary: '#221e3a',
        secondary: '#ab1425',
        accent: '#ed792b',
        success: '#ab1425', // Secondary
        error: '#ab1425' // Secondary
      }
    }
  }
})
