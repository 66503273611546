<template>
  <v-app>
    <v-app-bar v-if="$auth.check()" :class="appBarColor" app dark dense clipped-right>
      <v-btn icon v-if="$route.meta.parent !== null" :to="parentRoute" exact>
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-img
        src="/img/campfire_notext_white.png"
        alt="SYNC CampFire Logo"
        max-width="40"
        max-height="40"
        contain
      />
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              text icon color="orange"
              v-show="$root.isOffline"
              @click="tryReconnect">
            <v-icon>warning</v-icon>
          </v-btn>
        </template>
        <span>Application is offline. Click here to reconnect.</span>
      </v-tooltip>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge
              overlap
              color="primary"
              :value="notificationsNotCompleted > 0"
            >
              <template v-slot:badge>
                <span>{{ notificationsNotCompleted }}</span>
              </template>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <template v-if="$root.notifications.length > 0">
            <v-list-item
              v-for="notification in $root.notifications"
              :key="notification.id"
              :to="notification.to ? notification.to : '/notifications/' + notification.id"
              active-class=""
            >
              <v-list-item-content>
                <v-list-item-title>{{ notification.subject }}</v-list-item-title>
                <v-list-item-subtitle>{{ notification.displayDate }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="!notification.isCompleted">markunread</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item to="/notifications">
              <v-list-item-content>
                <v-list-item-title>View All...</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>No Notifications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon @click="rightDrawer = !rightDrawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar v-else-if="$route.path !== '/'" class="secondary" app dense clipped-right>
      <v-btn icon v-if="$route.meta.parent !== null" :to="parentRoute" exact>
        <v-icon>arrow_back</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$auth.check()"
      v-model="rightDrawer"
      class="secondary"
      dark
      hide-overlay
      right
      app
      clipped
      style="z-index: 9999"
    >
      <v-list dense>
        <v-list-item
            v-for="item in navItems"
            :key="item.title"
            :to="item.path">
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="primary">
      <template v-if="$auth.ready()">
          <router-view></router-view>
      </template>
      <template v-if="!$auth.ready()">
          Loading ...
      </template>
    </v-main>
    <v-snackbar
        v-model="$root.showMsg"
        :color="$root.msgType"
        top
        app
    >
      {{ $root.msgText }}
    </v-snackbar>
    <v-snackbar
      :value="showInstallationPromptIndicator"
      bottom
      :multi-line="true"
      :timeout="-1"
      color="accent"
    >
      <v-btn
        @click="pwaPromptInstallation"
        color="primary"
        block
        class="ma-0"
        style="width: 100%"
      >
        Install as App
      </v-btn>
      <template v-slot:action>
        <v-btn
            @click="pwaInstallationPromptFinished = true"
            color="primary"
            block
            icon
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="pwaShowPromptDialog"
    >
      <v-card>
        <v-card-title class="text-center">
          <p class="text-h5" style="width: 100%">Press the share button, then "Add to Home Screen"</p>
          <p class="subheading" style="width: 100%">(you may have to scroll to see the share button)</p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col class="shrink">
              <v-icon>arrow_downward</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import type { UserInterface } from '#/@websanova/vue-auth'

import * as PwaApiEnum from '@/types/pwa-api/enum'

const NOTIFICATION_ID_SUBSCRIPTION_WARNING = -1

export default Vue.extend({
  name: 'App',
  data: () => ({
    rightDrawer: false,
    search: null,
    tryReconnectTimeoutId: null as number|null,
    tryReconnectInterval: null as number|null,

    pwaStandaloneMode: false,
    pwaShowPromptDialog: false,
    pwaInstallationPrompt: null as BeforeInstallPromptEvent|null,
    pwaInstallationPromptFinished: false
  }),
  computed: {
    appBarColor: function (): string {
      if (!this.$auth.check()) {
        return ''
      }

      const u: UserInterface = this.$auth.user()
      if (u.roles.indexOf(PwaApiEnum.RoleTemplateId.GlobalAdmin) !== -1) {
        return 'secondary'
      } else if (u.roles.indexOf(PwaApiEnum.RoleTemplateId.OrganizationAdmin) !== -1) {
        return 'secondary'
      } else if (u.roles.indexOf(PwaApiEnum.RoleTemplateId.Camper) !== -1) {
        return 'accent'
      } else {
        return 'accent'
      }
    },
    notificationsNotCompleted: function () {
      return this.$root.notifications.reduce((c, n) => { return n.isCompleted ? c : c + 1 }, 0)
    },
    isSubscriptionExpiring: function () {
      return this.$root.isSubscriptionExpiring
    },
    isMobileSafari: function () {
      const ua = window.navigator.userAgent

      const isMobile = /iP(ad|hone)/i.test(ua)
      const isWebkit = /WebKit/i.test(ua)
      const isChrome = /CriOS/i.test(ua)

      return isMobile && isWebkit && !isChrome
    },
    showInstallationPromptIndicator: function () {
      // Make sure we aren't on a functional page
      const routes = [
        '/confirm',
        '/setup'
      ]
      let path = this.$route.path
      if (this.$route.meta && this.$route.meta.simplePath) {
        path = this.$route.meta.simplePath
      }
      if (routes.indexOf(path) !== -1) {
        return false
      }

      return this.pwaInstallationPromptFinished === false &&
        ((this.isMobileSafari && !this.pwaStandaloneMode) || this.pwaInstallationPrompt !== null)
    },
    parentRoute: function () {
      // If we have a direct value use it, otherwise we need to calculate
      if (this.$route.meta && this.$route.meta.parent !== undefined) {
        return this.$route.meta.parent
      } else {
        // Our general rule is to take the path and just remove the last part
        const pathParts = this.$route.path.split('/')
        pathParts.pop()
        let path = pathParts.join('/')

        // If we ended up with nothing, correct it to a valid path
        if (path === '') {
          path = '/'
        }

        return path
      }
    },
    navItems: function () {
      if (!this.$router.options || !this.$router.options.routes) {
        return []
      }
      // Return a usable list of navigation items that are meant to be rendered
      return this.$router.options.routes.map((route) => {
        return {
          title: (route.meta && route.meta.displayName) ? route.meta.displayName : 'test',
          path: (route.meta && route.meta.simplePath) ? route.meta.simplePath : route.path,
          shouldRender: () => {
            // If we don't have a valid display name, we don't render
            if (!route.meta || (route.meta && route.meta.displayName === null)) {
              return false
            }

            // If we are a sub-page, we don't render
            if (route.meta.isSubpage && route.meta.isSubpage === true) {
              return false
            }

            // If we are logged in as an ephemeral user and this route is not
            // allowed for them, we don't render
            if (route.meta.noEphemeral && (this.$auth.user()?.is_ephemeral || false) === true) {
              return false
            }

            // Otherwise, we base the decision on authentication status
            switch (typeof route.meta.auth) {
              case 'undefined':
                return true
              case 'boolean':
                return this.$auth.check() === route.meta.auth
              // string, array, or object will be handled the same way by
              // the auth check
              default:
                return this.$auth.check(route.meta.auth)
            }
          }
        }
      }).filter((item) => {
        return item.shouldRender()
      })
    }
  },
  watch: {
    isSubscriptionExpiring: function () {
      // Add or remove our subscription warning notification
      const i = this.$root.notificationsFromApp.findIndex(n => n.id === NOTIFICATION_ID_SUBSCRIPTION_WARNING)
      if (this.isSubscriptionExpiring) {
        const nowString = this.$root.getApiDateTimeString()
        if (i === -1) {
          this.$root.notificationsFromApp.push({
            id: NOTIFICATION_ID_SUBSCRIPTION_WARNING,
            subject: 'Subscription is Expiring Soon',
            body: '',
            to: '/subscriptions',
            created_at: nowString,
            updated_at: nowString,
            created_by: -1,
            source_role_context_id: PwaApiEnum.RoleContextId.Global,
            source_role_context_object_id: null,
            target_role_context_id: PwaApiEnum.RoleContextId.Self,
            target_role_context_object_id: this.$auth.user().id,
            start_time: this.getApiDateTimeString()
          })
        }
      } else {
        if (i !== -1) {
          this.$root.notificationsFromApp.splice(i, 1)
        }
      }
    }
  },
  mounted: function () {
    // Certain API error responses shouldn't result in immediate failure. We
    // add a response interceptor so we can try to get a better response in
    // the circumstances that we can.
    this.axios.interceptors.response.use(undefined, (err) => {
      // We don't want to return a real value right away because some of our
      // logic involves making more API requests
      return new Promise((resolve, reject) => {
        const path = err.config.url
        const res = err.response

        // We treat 401 (unauthenticated) responses specially
        if (res.status === 401) {
          // If the request is for our refresh endpoint, just make sure we're
          // logged out locally since we're apparently logged out remotely
          if (path === this.$auth.options.refreshData.url) {
            this.$auth.flashMessage = 'Session expired. Please log in again.'
            this.$auth.logout({ makeRequest: false, redirect: '/login' })
            reject(err)
            return
          }

          // If the request wasn't for our refresh endpoint, try to refresh our
          // access token and send the request again (unless the request was
          // specifically configured to ignore this 401 check)
          if (res.config.data) {
            const configData = JSON.parse(res.config.data)
            if (configData.ignore401 && configData.ignore401 === true) {
              reject(err)
              return
            }
          }
          this.$auth.refresh()
            .then(() => {
              // The refresh succeeded, which means we now have a good access
              // token. Try resending the request, minus the original
              // authorization header
              delete res.config.headers.Authorization
              this.axios.request(res.config)
                .then((res) => {
                  resolve(res)
                })
                .catch((e) => {
                  reject(e)
                })
            })
            .catch(() => {
              // The refresh failed, which means that not only was our access
              // token invalid (the original 401), our ID token is also invalid.
              // To make ure our state is accurate, we request a local logout
              // to prompt the user to log in again.
              this.$root.setRoutePath('/logout')
              reject(err)
            })
          return
          // eslint-disable-next-line
        }
        // We treat 402 (payment required) responses specially
        else if (res.status === 402) {
          // If an API request returns 402, it means that the user doesn't have
          // an active subscription as required by that request. We direct them
          // to another page with information about subscribing unless (1) the
          // request was specifically asking about subscription status, in which
          // case we assume that the requesting method will handle any necessary
          // redirection; or (2) we are not using subscriptions, in which case
          // we treat the 402 as a 403 instead.
          if (this.$root.featureSubscriptions === false) {
            this.$root.setRoutePath('/403')
            reject(err)
            return
          }
          if (path !== 'status/subscription') {
            this.$root.setRoutePath('/subscribe')
            reject(err)
            return
          }
          // eslint-disable-next-line
        }
        // We treat 500+ (server error) responses specially
        else if (res.status >= 500) {
          // Since we're not responsible for whatever caused this response, we
          // want to retry our request again (up to MAX_RETRIES number of times)
          const MAX_RETRIES = 3

          // We don't want to retry file uploads since they're more complicated
          // and resource intensive
          if (path.startsWith('files/uploads')) {
            reject(err)
            return
          }

          // Make sure we haven't already exceeded our limit of retries
          let retries = 0
          const d = res.config.data
          if (typeof d._retries !== 'undefined') {
            retries = d._retries
          }
          if (retries < MAX_RETRIES) {
            d._retries = retries + 1
            res.config.data = JSON.stringify(d)
            this.axios.request(res.config)
              .then((res) => {
                resolve(res)
              })
              .catch(() => {
                reject(err)
              })
            return
          }
        }

        // If we didn't have a more specific handler, let normal processes run
        reject(err)
      })
    })

    // Add a listener so we can prompt the user about installation if they're not
    // already using it in standalone mode
    this.pwaInstallationPrompt = null
    if (!window.matchMedia('(display-mode: standalone)').matches &&
        window.navigator.standalone !== true) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.pwaInstallationPrompt = (e as BeforeInstallPromptEvent)
      })
    } else {
      this.pwaStandaloneMode = true
    }

    // Add a listener on our service worker so we can receive messages
    navigator.serviceWorker.addEventListener('message', (ev) => {
      switch (ev.data) {
        case 'OFFLINE':
          this.$root.isOffline = true
          if (this.tryReconnectTimeoutId === null) {
            this.tryReconnectInterval = 3000
            this.tryReconnectTimeoutId = setTimeout(this.tryReconnect, this.tryReconnectInterval)
          }
          break
      }
    })

    // If we are using the subscription feature, then set up an interval for
    // checking our subscription status and run it once right away (allowing a
    // little bit of time for setup to finish)
    if (this.featureSubscriptions) {
      setTimeout(this.$root.subscriptionCheck, 2000)
      setInterval(this.$root.subscriptionCheck, (1000 * 60 * 60 * 12)) // every 12 hours
    }

    // Set up an interval for checking for notifications and their completions
    // and run it once right away (allowing a bit of time for setup to finish)
    setTimeout(this.$root.notificationCheck, 2000)
    setInterval(this.$root.notificationCheck, (1000 * 60 * 5)) // every 5 minutes
  },
  methods: {
    tryReconnect: function (ev: MouseEvent) {
      this.axios.get('/status').then(() => {
        // Not offline anymore
        this.$root.isOffline = false
        if (this.tryReconnectTimeoutId) {
          clearTimeout(this.tryReconnectTimeoutId)
          this.tryReconnectTimeoutId = null
        }
      }).catch(() => {
        // Still offline
        // Unless this test was user-initiated, increase our reconnection
        // interval by 2x, up to a max of 5 minutes
        if (!ev) {
          this.tryReconnectInterval = Math.min((this.tryReconnectInterval as number) * 2, (1000 * 60 * 5))
          this.tryReconnectTimeoutId = setTimeout(this.tryReconnect, this.tryReconnectInterval)
        }
      })
    },
    pwaPromptInstallation: function () {
      // Mobile Safari requires our own instructional dialog
      if (this.isMobileSafari) {
        this.pwaShowPromptDialog = true
        this.pwaInstallationPromptFinished = true
        return
      }

      if (this.pwaInstallationPrompt === null) {
        return
      }

      // Show the prompt and wait for a response
      this.pwaInstallationPrompt.prompt()
      this.pwaInstallationPromptFinished = true
      this.pwaInstallationPrompt.userChoice
        .then(() => {
          this.pwaInstallationPrompt = null
        })
    },
    getAuthenticatedFileURL: function (fileID: number, allowCache: boolean) {
      let tag = ''
      if (allowCache === true) {
        tag = '&cache'
      }
      return this.axios.defaults.baseURL + '/files/' + fileID + '?bearer_token=' + this.$auth.token() + tag
    }
  }
})
</script>
