import type { VueAuth } from '#/@websanova/vue-auth'
import type * as Axios from 'axios'

// Based on the Bearer driver, but includes logic to store an ID token and not
// just an access token
export default {
  request: function (this: VueAuth, req: Axios.AxiosRequestConfig, token: string): void {
    // We use the ID token if this is a refresh request, otherwise we will use
    // the access token (default name)
    if (req.url === this.options.refreshData.url) {
      token = this.token('id')
    }

    this.drivers.http.setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token
    })
  },
  response: function (this: VueAuth, res: Axios.AxiosResponse): string|void {
    // We don't have anything we can rely on if we don't know what we're
    // responding to
    if (!res.config || !res.config.url) {
      return
    }

    // We expect an ID token in the body if this was a login request
    if (res.config.url === this.options.loginData.url ||
        res.config.url.startsWith('auth/google') ||
        res.config.url.startsWith('auth/facebook')) {
      if (res.data && res.data.token) {
        this.token('id', res.data.token)
      }
    }

    // If our response contains an Authorization header, store its value as
    // our access token (default action for this intercept, so we store it by
    // returning rather than calling token())
    const headers = this.drivers.http.getHeaders.call(this, res)
    const token = headers.Authorization || headers.authorization

    if (token) {
      const parts = token.split(/Bearer:?\s?/i)

      return parts[parts.length > 1 ? 1 : 0].trim()
    }
  }
}
