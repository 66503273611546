import { defineStore } from 'pinia'
import { useHelperStore } from './helper'

import * as PwaApi from '#/pwa-api'
import type { BodyData } from './helper'
import type { AxiosStatic } from 'axios'

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    helperStore: useHelperStore(),

    notifications: [] as Array<PwaApi.Notification>
  }),
  actions: {
    clear: function () {
      this.helperStore.waitFetchPathPromise('/notifications', () => { this.notifications = [] })
    },
    fetchNotifications: function (axios: AxiosStatic): Promise<void> {
      return this.helperStore.getFetchPathPromise(axios, '/notifications', (values) => {
        this.notifications = values
      })
    },
    upsertNotification: function (axios: AxiosStatic, data: BodyData): Promise<number> {
      return this.helperStore.upsertObject(
        axios,
        data,
        '/notifications',
        `/notifications/${data.id}`,
        this.notifications,
        ['id', 'subject', 'body', 'created_by', 'source_role_context_id', 'source_role_context_object_id', 'target_role_context_id', 'target_role_context_object_id', 'start_time'],
        ['subject', 'body', 'source_role_context_id', 'source_role_context_object_id', 'target_role_context_id', 'target_role_context_object_id', 'start_time']
      )
    },
    removeNotification: function (axios: AxiosStatic, id: number) {
      return this.helperStore.removeObject(axios, id, `/notifications/${id}`, this.notifications)
    }
  }
})
