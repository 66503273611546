// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useSubscriptionsStore } from './store/subscriptions'
import vuetify from './plugins/vuetify'
import { UtilData, UtilComputed, UtilMethods } from './plugins/util'
import 'vuetify/dist/vuetify.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import driverOAuth2Google from '@websanova/vue-auth/dist/drivers/oauth2/google.esm.js'
import driverOAuth2Facebook from '@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js'
import driverAuthCustom from './plugins/vue-auth-driver'
import driverHttpAxiosCustom from './plugins/vue-http-driver'

import type * as VueAuthTypes from '#/@websanova/vue-auth'
import * as PwaApiEnum from '@/types/pwa-api/enum'

// Set up Axios for API requests
Vue.use(VueAxios, axios)
switch (process.env.NODE_ENV) {
  case 'development':
    Vue.axios.defaults.baseURL = 'https://api.local.com'
    break
  case 'testing':
    // If we are running a server for tests, we want to make sure the API knows it
    Vue.axios.defaults.headers.common['X-SYNC-Test'] = true
    Vue.axios.defaults.baseURL = 'https://172.20.2.101'
    break
  default:
    Vue.axios.defaults.baseURL = process.env.VUE_APP_SYNC_API_ORIGIN || ''
}

// Set up necessary components for vue-auth
Vue.use(VueAuth as VueAuthTypes.VueAuth, {
  plugins: {
    http: Vue.axios,
    router
  },
  drivers: {
    auth: driverAuthCustom,
    http: driverHttpAxiosCustom,
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook
    }
  },
  options: {
    // Configure our token refresh to occur automatically every 5 minutes
    refreshData: { enabled: true, interval: 5 }
  }
})

// Initialize Pinia for the application. Individual stores are created and
// imported in components as needed, and since we need the Vue.use() call here
// anyway, we handle the plugin directly instead of importing from another file.
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false

/* eslint-disable no-new */
const v = new Vue({
  data: UtilData,
  computed: UtilComputed,
  methods: UtilMethods,
  vuetify,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')

// Extend our vue-auth object to check for an active subscription
v.$auth.isSubscribed = function () {
  // If we're not using the subscriptions feature, return a generally positive
  // response instead of actually checking
  if (v.$root.featureSubscriptions === false) {
    return Promise.resolve(true)
  }

  // Perform a real subscription check
  const subscriptionsStore = useSubscriptionsStore()
  return axios.get('/status/subscription')
    .then(() => {
      // If we got a success response, then we know easily that we have an
      // active subscription. Send off a request for subscription details so
      // they'll be available in the future, then return our success.
      subscriptionsStore.fetchSubscriptions(axios).catch(() => { /* do nothing */ })
      return true
    })
    .catch(async (e) => {
      // If we got an error with a 402 status code, then we know easily that we
      // do not have an active subscription
      if (e && e.response && e.response.status && e.response.status === 402) {
        return false
      }

      // If we failed for another reason (e.g., network offline), then we return
      // the best answer we can based on our cached data
      const subs = (await this.subscriptions()).filter((sub) => {
        return sub.isActive
      })
      return (subs.length > 0) || (this.user().roles.indexOf(PwaApiEnum.RoleTemplateId.GlobalAdmin) !== -1)
    })
}

// Extend our vue-auth object to return a list of relevant subscriptions
v.$auth.subscriptions = async function () {
  // If we're not using the subscriptions feature, return an empty list
  if (v.$root.featureSubscriptions === false) {
    return []
  }

  // Perform a real lookup
  const subscriptionsStore = useSubscriptionsStore()
  try {
    await subscriptionsStore.fetchSubscriptions(axios, true)
    const subs = subscriptionsStore.subscriptions
    const now = new Date()
    subs.forEach((sub) => {
      // Get more usable date values
      const props = [
        'created_at',
        'start_date',
        'end_date',
        'approval_date',
        'cancel_date',
        'terminal_date'
      ]
      props.forEach((p) => {
        if (sub[p] !== null) {
          sub[p] = v.$root.parseApiDateTimeString(sub[p] as string)
        }
      })

      // Set a "next billing cycle" value for convenience
      sub.nextCycle = (sub.terminal_date !== null && sub.end_date >= sub.terminal_date) ? null : (sub.end_date as Date)

      // Determine if the subscription's dates are currently relevant
      if (now >= sub.start_date && now <= sub.end_date) {
        sub.isCurrent = true
      } else {
        sub.isCurrent = false
      }

      // Determine if the subscription is actually good for granting access
      sub.isActive = (
        sub.isCurrent &&
        (sub.hasActiveHold === false)
      )
    })

    // Order by descending terminal date (counting a null terminal date
    // essentially as positive infinity)
    subs.sort((a, b) => {
      if (a.terminal_date === null) {
        return -1
      }
      if (b.terminal_date === null) {
        return 1
      }
      if (a.terminal_date > b.terminal_date) {
        return -1
      }
      if (b.terminal_date > a.terminal_date) {
        return 1
      }
      return 0
    })

    return subs
  } catch (e) {
    return []
  }
}

// Add a navigation guard to our router now that our Vue object is fully initialized
router.beforeResolve(async function (to, from, next) {
  // Set some rules for when there is a logged in user and the target route
  // has meta flags
  if (v.$auth.check() &&
      typeof to.meta !== 'undefined') {
    // Make sure the user has confirmed their email address if they're using
    // a local auth method. The user's profile will indicate if they have
    // confirmed an auth_local email.
    const u = v.$auth.user()
    if ((typeof to.meta.ignoreEmailConfirmation === 'undefined' || to.meta.ignoreEmailConfirmation !== true) &&
        u.local.email !== null && u.local.email_confirmed_at === null) {
      return next('/confirm')
    }

    // Make sure the user has completed their basic profile information (unless
    // they are an ephemeral user, in which case they have no reason to provide
    // this type of information)
    if ((u.is_ephemeral === false) &&
        (typeof to.meta.ignoreProfileCompletion === 'undefined' || to.meta.ignoreProfileCompletion !== true) &&
        (u.first_name === null || u.first_name.trim() === '')) {
      return next('/profile-setup')
    }

    // Make sure the user has completed setup if that flag is set
    if (typeof to.meta.setup !== 'undefined' &&
        to.meta.setup === true) {
      // The test for a completed setup process is the presence of a visible
      // user role. Not every user needs such a role, but it is assumed that if
      // we put the setup flag on a route, then it does need one.
      if (v.$auth.user().roles.length === 0) {
        return next('/setup')
      }
    }

    // Make sure the user has an active subscription if that flag is set
    if (typeof to.meta.subscriptionRequired !== 'undefined' &&
        to.meta.subscriptionRequired === true) {
      if (await v.$auth.isSubscribed() !== true) {
        return next('/subscribe')
      }
    }

    // Make sure the user is not ephemeral if that flag is set
    if (typeof to.meta.noEphemeral !== 'undefined' &&
        to.meta.noEphemeral === true) {
      if (u.is_ephemeral) {
        return next('/')
      }
    }
  }

  // If we make it this far without more specific requirements, just allow the route
  return next()
})
