import Vue from 'vue'
import Router from 'vue-router'

import * as PwaApiEnum from '@/types/pwa-api/enum'

Vue.use(Router)

export interface RouteDefinitionMetaData {
  displayName: string|null
  auth?: boolean|PwaApiEnum.RoleTemplateId|Array<PwaApiEnum.RoleTemplateId>
  objectType?: PwaApiEnum.ObjectTypeId
  setup?: boolean
  ignoreEmailConfirmation?: boolean
  ignoreProfileCompletion?: boolean
  subscriptionRequired?: boolean
  simplePath?: string
  isSubpage?: boolean
  parent?: string|null
  noEphemeral?: boolean
  features?: Array<'subscriptions'>
}

export interface RouteDefinition {
  path: string
  component: string
  meta: RouteDefinitionMetaData
}

// Meta fields:
// -displayName = The human-readable name to render in a navigation area.
// -auth = The authentication requirement for viewing the page. If false or not
//         set, no authentication is required. If true, a login of any sort is
//         required. See https://github.com/websanova/vue-auth/blob/master/docs/Methods.md#check
//         for more detailed options.
// -objectType = The object type the requesting user must have viewing
//               permissions for in order to access the page. Not just relevant
//               for pages with auth requirements, because guest users are
//               assigned certain rights as well. Note: Enforcement of this
//               value is left up to individual routes because the logic for it
//               is very situational.
// -setup = true if a user is required to have completed the setup process before
//          viewing the page; false or unset to have no setup requirement. If
//          set to true without any auth requirement, it will be ignored for
//          unauthenticated requests.
// -ignoreEmailConfirmation = true if we should skip checking whether or not the
//                            user has confirmed their auth_local email.
// -ignoreProfileCompletion = true if we should skip checking whether or not the
//                            user has completed their basic profile fields.
// -subscriptionRequired = true if a user must have an active subscription before
//                         being allowed to access the page.
// -simplePath = A more basic form of the route path to use for navigation instead
//               of "path" (useful if path contains params and/or regex).
// -isSubpage = The route is not a top-level page but is meant to be navigated to
//              through another page. This will hide the route from top-level
//              navigation.
// -parent = The route path that is the parent of the current path (used for
//           navigating back a level). Can be null to indicate that no navigation
//           should be available from the route.
// -noEphemeral = The route is not allowed for ephemeral users.
// -features = Array of app-level features that must be enabled in order for the
//             route to be included in the final list (based on FEATURE
//             environment variables)
let routerSetup: RouteDefinition[] = [
  {
    path: '/',
    component: 'Home',
    meta: {
      displayName: 'Home',
      parent: null,
      setup: true
    }
  },
  {
    path: '/profile-setup',
    component: 'ProfileSetup',
    meta: {
      displayName: null,
      auth: true,
      parent: null,
      ignoreProfileCompletion: true
    }
  },
  {
    path: '/setup',
    component: 'Setup',
    meta: {
      displayName: null,
      auth: true,
      parent: null
    }
  },
  {
    path: '/confirm/:token?',
    component: 'Confirm',
    meta: {
      simplePath: '/confirm',
      displayName: null,
      auth: true,
      ignoreEmailConfirmation: true,
      ignoreProfileCompletion: true,
      parent: null
    }
  },
  {
    path: '/forgot',
    component: 'Forgot',
    meta: {
      displayName: null,
      auth: false,
      parent: '/login'
    }
  },
  {
    path: '/forgot/finish/:token?',
    component: 'Forgot-Finish',
    meta: {
      simplePath: '/forgot/finish',
      displayName: null,
      auth: false,
      parent: '/login'
    }
  },
  {
    path: '/account',
    component: 'Account',
    meta: {
      displayName: 'Account',
      auth: true,
      noEphemeral: true
    }
  },
  {
    path: '/login',
    component: 'Login',
    meta: {
      displayName: 'Log In',
      auth: false
    }
  },
  {
    path: '/logout',
    component: 'Logout',
    meta: {
      displayName: 'Log Out',
      auth: true,
      ignoreEmailConfirmation: true,
      ignoreProfileCompletion: true
    }
  },
  {
    path: '/oauth2',
    component: 'Login-OAuth2',
    meta: {
      displayName: null
    }
  },
  {
    path: '/signup',
    component: 'Signup',
    meta: {
      displayName: 'Sign Up',
      auth: false
    }
  },
  {
    path: '/subscribe/:action(approve|cancel)?',
    component: 'Subscribe',
    meta: {
      displayName: null,
      auth: true,
      features: ['subscriptions']
    }
  },
  {
    path: '/subscriptions/:id(\\d+)?',
    component: 'Subscriptions',
    meta: {
      displayName: null,
      auth: true,
      features: ['subscriptions']
    }
  },
  {
    path: '/invoices/:id(\\d+)',
    component: 'Invoices-Single',
    meta: {
      displayName: null,
      auth: true,
      parent: '/subscriptions',
      features: ['subscriptions']
    }
  },
  {
    path: '/invoices/payment/:action(approve|cancel)',
    component: 'Invoices-Payment',
    meta: {
      displayName: null,
      auth: true,
      parent: null,
      features: ['subscriptions']
    }
  },
  {
    path: '/notifications',
    component: 'Notifications',
    meta: {
      displayName: null,
      auth: true
    }
  },
  {
    path: '/notifications/:id(\\d+)',
    component: 'Notifications-Single',
    meta: {
      displayName: null,
      auth: true
    }
  },
  {
    path: '/admin',
    component: 'admin/Index',
    meta: {
      displayName: 'Admin Page',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin
    }
  },
  {
    path: '/admin/users',
    component: 'admin/Users',
    meta: {
      displayName: 'Users',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.User,
      isSubpage: true
    }
  },
  {
    path: '/admin/users/:id(\\d+|new)',
    component: 'admin/Users-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.User
    }
  },
  {
    path: '/admin/roles',
    component: 'admin/Roles',
    meta: {
      displayName: 'Roles',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Role,
      isSubpage: true
    }
  },
  {
    path: '/admin/roles/:id(-?\\d+|new)',
    component: 'admin/Roles-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Role
    }
  },
  {
    path: '/admin/jobs',
    component: 'admin/Jobs',
    meta: {
      displayName: 'Scheduled Jobs',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.ScheduledJob,
      isSubpage: true
    }
  },
  {
    path: '/admin/organizations',
    component: 'admin/Organizations',
    meta: {
      displayName: 'Organizations',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Organization,
      isSubpage: true
    }
  },
  {
    path: '/admin/organizations/:id(\\d+|new)',
    component: 'admin/Organizations-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Organization
    }
  },
  {
    path: '/admin/pricing',
    component: 'admin/Pricing',
    meta: {
      displayName: 'Pricing and Billing',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.SubscriptionPricingDefinition,
      isSubpage: true,
      features: ['subscriptions']
    }
  },
  {
    path: '/admin/subscriptions',
    component: 'admin/Subscriptions',
    meta: {
      displayName: 'Subscriptions',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Subscription,
      isSubpage: true,
      features: ['subscriptions']
    }
  },
  {
    path: '/admin/subscriptions/:id(\\d+|new)',
    component: 'admin/Subscriptions-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Subscription,
      features: ['subscriptions']
    }
  },
  {
    path: '/admin/subscriptions/:id(\\d+)/holds',
    component: 'admin/Subscriptions-Holds',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.SubscriptionHold,
      parent: '/admin/subscriptions',
      features: ['subscriptions']
    }
  },
  {
    path: '/admin/subscriptions/:id(\\d+)/invoices',
    component: 'admin/Subscriptions-Invoices',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Invoice,
      parent: '/admin/subscriptions',
      features: ['subscriptions']
    }
  },
  {
    path: '/admin/notifications',
    component: 'admin/Notifications',
    meta: {
      displayName: 'Notifications',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Notification,
      isSubpage: true
    }
  },
  {
    path: '/admin/notifications/:id(\\d+|new)',
    component: 'admin/Notifications-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      objectType: PwaApiEnum.ObjectTypeId.Notification
    }
  },
  {
    path: '/admin/lessons',
    component: 'admin/Lessons',
    meta: {
      displayName: 'Lessons',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      isSubpage: true
    }
  },
  {
    path: '/admin/lessons/:id(\\d+|new)',
    component: 'admin/Lessons-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin
    }
  },
  {
    path: '/admin/lessons/:id(\\d+)/preview',
    component: 'admin/Lessons-Single-Preview',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin
    }
  },
  {
    path: '/admin/lesson-sets',
    component: 'admin/LessonSets',
    meta: {
      displayName: 'Lesson Sets',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      isSubpage: true
    }
  },
  {
    path: '/admin/lesson-sets/:id(\\d+|new)',
    component: 'admin/LessonSets-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin
    }
  },
  {
    path: '/admin/lesson-set-sequences',
    component: 'admin/LessonSetSequences',
    meta: {
      displayName: 'Lesson Set Sequences',
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin,
      isSubpage: true
    }
  },
  {
    path: '/admin/lesson-set-sequences/:id(\\d+|new)',
    component: 'admin/LessonSetSequences-Single',
    meta: {
      displayName: null,
      auth: PwaApiEnum.RoleTemplateId.GlobalAdmin
    }
  },
  {
    path: '/manage',
    component: 'manage/Index',
    meta: {
      displayName: 'Management Page',
      auth: [PwaApiEnum.RoleTemplateId.GlobalAdmin, PwaApiEnum.RoleTemplateId.OrganizationAdmin]
    }
  },
  {
    path: '/manage/organization/:id(\\d+)?',
    component: 'manage/Organization',
    meta: {
      displayName: null,
      auth: [PwaApiEnum.RoleTemplateId.GlobalAdmin, PwaApiEnum.RoleTemplateId.OrganizationAdmin],
      isSubpage: true
    }
  },
  {
    path: '/manage/camp-session/:id(\\d+)',
    component: 'manage/CampSession',
    meta: {
      displayName: null,
      auth: [PwaApiEnum.RoleTemplateId.GlobalAdmin, PwaApiEnum.RoleTemplateId.OrganizationAdmin, PwaApiEnum.RoleTemplateId.CampSessionCampCoordinator],
      isSubpage: true,
      parent: '/manage'
    }
  },
  {
    path: '/manage/camp-session-church/:id(\\d+)',
    component: 'manage/CampSessionChurch',
    meta: {
      displayName: null,
      auth: [PwaApiEnum.RoleTemplateId.GlobalAdmin, PwaApiEnum.RoleTemplateId.OrganizationAdmin, PwaApiEnum.RoleTemplateId.CampSessionCampCoordinator, PwaApiEnum.RoleTemplateId.CampSessionChurchCoordinator],
      isSubpage: true,
      parent: '/manage'
    }
  },
  {
    path: '/manage/lessons/:id(\\d+)/preview',
    component: 'manage/LessonPreview',
    meta: {
      displayName: null,
      auth: [PwaApiEnum.RoleTemplateId.GlobalAdmin, PwaApiEnum.RoleTemplateId.OrganizationAdmin, PwaApiEnum.RoleTemplateId.CampSessionCampCoordinator, PwaApiEnum.RoleTemplateId.CampSessionChurchCoordinator],
      isSubpage: true,
      parent: '/manage'
    }
  },
  {
    path: '/lessons',
    component: 'Lessons',
    meta: {
      displayName: 'Lessons',
      auth: [PwaApiEnum.RoleTemplateId.Camper]
    }
  },
  {
    path: '/lessons/:which(today)',
    component: 'Lessons',
    meta: {
      displayName: null,
      auth: [PwaApiEnum.RoleTemplateId.Camper]
    }
  },
  {
    path: '/camp/session/church/:camp_session_church_id(\\d+)/lessons/:id(\\d+)?',
    component: 'CampSessionChurch-Lessons',
    meta: {
      displayName: null,
      auth: true,
      isSubpage: true
    }
  },
  {
    path: '/403',
    component: '403',
    meta: {
      displayName: null
    }
  },
  // Final catch-all route, must come at the end
  {
    path: '*',
    component: '404',
    meta: {
      displayName: null,
      parent: '/'
    }
  }
]
// Add any environment-specific routes
if (process.env.NODE_ENV === 'development') {
  routerSetup.unshift({
    path: '/debug',
    component: 'Debug',
    meta: {
      displayName: 'Debugging'
    }
  })
}

// Filter out any routes that do not meet the current feature set
routerSetup = routerSetup.filter((f) => {
  // If we have no feature limiting on the route, there is nothing to do
  if (!f.meta || !f.meta.features || f.meta.features.length === 0) {
    return true
  }

  // Test each feature
  if ((process.env.VUE_APP_SYNC_FEATURE_SUBSCRIPTIONS || 'true') === 'false' && f.meta.features.indexOf('subscriptions') !== -1) {
    return false
  }

  // If we made it this far, it's fine to include the route
  return true
})

// Build a final list of actual routes
const routes = routerSetup.map(route => {
  return {
    ...route,
    component: () => import(`@/components/pages/${route.component}-Page.vue`)
  }
})

export default new Router({
  mode: 'history',
  routes
})
