// Not technically a Vue plugin, but we're using it in about the same way,
// extending our $root Vue object with methods and properties
import type * as PwaApi from '#/pwa-api'
import type Vue from 'vue'

import { useNotificationsStore } from '@/store/notifications'

import { DateTime } from 'luxon'

const UtilData = (): CustomVueData => ({
  isOffline: false,

  isSubscriptionExpiring: false,

  notificationsFromApi: [],
  notificationsFromApp: [],

  showMsg: false,
  msgType: '',
  msgText: ''
})

const UtilComputed: CustomVueComputed = {
  featureSubscriptions: function (): boolean {
    return (process.env.VUE_APP_SYNC_FEATURE_SUBSCRIPTIONS || 'true') !== 'false'
  },
  isGoogleAvailable: function () {
    return !!process.env.VUE_APP_SYNC_OAUTH2_GOOGLE_CLIENT_ID
  },
  isFacebookAvailable: function () {
    return !!process.env.VUE_APP_SYNC_OAUTH2_FACEBOOK_CLIENT_ID
  },
  notifications: function (this: Vue) {
    // Join our two notification sources and sort them with the most recent first
    const notifications = this.notificationsFromApp.concat(this.notificationsFromApi).sort((a, b) => {
      if (a.start_time < b.start_time) {
        return 1
      }
      if (a.start_time > b.start_time) {
        return -1
      }
      return 0
    })

    // Add a display date to each of our notifications
    for (const n of notifications) {
      const d = this.parseApiDateTimeString(n.start_time)
      n.displayDate = d.toLocaleString()
    }

    // Return our final collection
    return notifications
  }
}

const UtilMethods: CustomVueMethods = {
  // Helper to prevent us from having to write the same catch stub every time
  // we navigate via a router push
  setRoutePath (this: Vue, path: string) {
    this.$router.push(path).catch((err) => {
      // If we don't catch this error, it will be shown to the user very obviously
      console.log(err)
    })
  },

  googleLogin (this: Vue) {
    this.$auth.oauth2('google', {
      staySignedIn: true,
      params: {
        client_id: process.env.VUE_APP_SYNC_OAUTH2_GOOGLE_CLIENT_ID,
        scope: 'openid email profile',
        redirect_uri: this.$auth.options.getUrl() + '/oauth2?type=google'
      }
    })
  },

  facebookLogin (this: Vue) {
    this.$auth.oauth2('facebook', {
      staySignedIn: true,
      params: {
        client_id: process.env.VUE_APP_SYNC_OAUTH2_FACEBOOK_CLIENT_ID,
        display: 'popup',
        redirect_uri: this.$auth.options.getUrl() + '/oauth2?type=facebook'
      }
    })
  },

  /**
   * Gets a single, usable email address value for a user, preferring their
   * local email and then iterating social emails.
   *
   * @param user The user object as retrieved from the API.
   * @returns The user's email, or null if none is present.
   */
  getUserEmail: function (this: Vue, user) {
    let email = user.local.email
    if (email === null) {
      if (user.google.email !== null) {
        email = user.google.email
      } else if (user.facebook.email !== null) {
        email = user.facebook.email
      }
    }
    return email
  },

  // Generator method for handling API errors. It parses the error response
  // generically before calling a custom handler for rendering (if provided).
  // The custom handler will be passed the following arguments:
  // * errMessage - A broad-level description of the problem
  // * errors - Individual, field-level descriptions of specific problems
  // * err - The original error response
  apiErrorHandler: function (this: Vue, customHandler: PwaApi.ErrorResponseHandler) {
    return (err) => {
      // If we're not in production, we can print out the details of the error
      // for sake of debugging
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('API Request Error:', JSON.stringify(err))
      }

      // Make sure our response is in the format we're expecting. Otherwise,
      // we might be getting an error because we're offline and requesting a
      // non-cached resource. If neither of those is the case, we'll report a
      // generic failure, but there's not much else we can do.
      let errMessage = ''
      let errors = {}
      if (err && err.response) {
        // If we have message values, use them directly. Otherwise, make up a
        // generic error message based on our status code.
        if (err.response.data && err.response.data.err) {
          if (typeof err.response.data.err === 'string') {
            errMessage = err.response.data.err
          } else {
            errors = err.response.data.err
            if (err.response.data.msg) {
              errMessage = err.response.data.msg
            }
          }
        } else {
          switch (err.response.status) {
            case 400:
              errMessage = 'There was a problem with the input you provided'
              break
            case 401:
              errMessage = 'You must be logged in to access the resource you requested'
              break
            case 402:
              errMessage = 'You must have an active subscription to access the resource you requested'
              break
            case 403:
              errMessage = 'You do not have permission to access the resource you requested'
              break
            case 404:
              errMessage = 'The resource you requested does not exist, or you did not specify a resource'
              break
            case 405:
              errMessage = 'The resource you requested does not accept the action you tried to take'
              break
            case 409:
              errMessage = 'Your request conflicts with another resource'
              break
            default:
              errMessage = 'There was a problem accessing the resource you requested'
              break
          }
        }
      } else if (this.isOffline) {
        errMessage = 'The resource you requested is not currently available offline'
      } else {
        errMessage = 'There was a problem making a network request. Please try again.'
      }

      // Now that we've parsed the response, we can call the custom handler if
      // it was given. The handler can return something other than true (or not
      // return a value) to prevent our default rendering scheme.
      let shouldRender = true
      if (customHandler && typeof customHandler === 'function') {
        shouldRender = customHandler(errMessage, errors, err)
      }

      // If there was no handler or if it returned true, we show the error as
      // a notification banner.
      if (shouldRender) {
        this.showFlashMessage(errMessage)
      }
    }
  },

  // Helper utility to show a flash message from anywhere in the app
  showFlashMessage: function (this: Vue, msg, type = 'error') {
    this.msgText = msg
    this.msgType = type
    this.showMsg = true
  },

  // Takes a SQL UTC yyyy-mm-dd hh:mm:ss string and returns a Date object
  parseApiDateTimeString: function (d, targetTimeZone) {
    if (targetTimeZone === undefined) {
      targetTimeZone = 'America/Chicago'
    }

    // Some SQL datetime data types could have more information beyond seconds,
    // so we try parsing as an ISO string first to accommodate that
    let parsed = DateTime.fromISO(d, { zone: 'UTC' })
    if (!parsed.isValid) {
      parsed = DateTime.fromSQL(d, { zone: 'UTC' })
    }

    return parsed.setZone(targetTimeZone).toJSDate()
  },

  // Takes a Date object and returns a SQL UTC string. If no date given, the
  // current date/time will be used.
  getApiDateTimeString: function (d) {
    if (typeof d === 'undefined') {
      d = new Date()
    }

    return DateTime.fromJSDate(d)
      .setZone('UTC')
      .toSQL()
      .substr(0, 19) // cut off anything after seconds
  },

  // Generate a random string of the specified length from the characters in
  // the specified character set string
  getRandomString: function (length = 32, characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz') {
    let ret = ''
    for (let i = 0; i < length; i++) {
      ret += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
    }

    return ret
  },

  subscriptionCheck: async function (this: Vue) {
    // If we're not logged in or otherwise have no subscription, we don't need
    // to worry about our subscription expiring
    const s = await this.$auth.subscriptions()
    if (s.length === 0) {
      this.isSubscriptionExpiring = false
      return
    }

    // We only care about the farthest-reaching subscription
    const sub = s[0]
    if (sub.start_date && sub.start_date <= (new Date()) && sub.nextCycle === null) {
      this.isSubscriptionExpiring = true
    } else {
      this.isSubscriptionExpiring = false
    }
  },

  notificationCheck: function (this: Vue) {
    // If we're not logged in, there's nothing to do other than empty out our
    // list of API-backed notifications
    if (!this.$auth.check()) {
      this.notificationsFromApi = []
      return
    }

    // Get the notifications visible to the current user
    const notificationsStore = useNotificationsStore()
    notificationsStore.fetchNotifications(this.axios)
      .then(() => {
        // Filter down to the notifications that are meant for consumption
        const notifications = notificationsStore.notifications.filter((n) => {
          return n.capabilities && n.capabilities.consume && n.capabilities.consume === true
        })

        // Add an isCompleted flag to each of our notifications, assuming false
        // to start with (but to be filled in next)
        for (const n of notifications) {
          n.isCompleted = false
        }

        // Prepare to gather completion data for each of our notifications
        const reqs = []
        for (const n of notifications) {
          reqs.push(this.axios.get(`/notifications/${n.id}/completions`))
        }

        // Gather completion data
        const uid = this.$auth.user().id
        this.axios.all(reqs)
          .then((responses) => {
            for (const res of responses) {
              // Iterate the completions in this response
              const completions: Array<PwaApi.NotificationCompletion> = res.data
              for (const c of completions) {
                if (c.user_id !== uid) {
                  continue
                }

                // Store the associated notification if we can find it
                const n = notifications.find((n) => {
                  return n.id === c.notification_id
                })

                // If we have a completion match, record it
                if (n) {
                  n.isCompleted = true
                }
              }
            }
          })
          .catch(() => { /* swallow error */ })
          .finally(() => {
            this.notificationsFromApi = notifications
          })
      })
      .catch(() => { /* swallow error */ })
  },
  swSendMessage: function (this: Vue, msg) {
    if ('serviceWorker' in navigator) {
      return new Promise(function (resolve, reject) {
        const chan = new MessageChannel()

        // Create a handler for the response message
        chan.port1.onmessage = function (ev) {
          if (ev.data.error) {
            reject(ev.data.error)
          } else {
            resolve(ev.data)
          }
        }

        // Send our message with a mechanism for reply
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage(msg, [chan.port2])
        }
      })
    } else {
      return Promise.reject(new Error('Service Worker not available'))
    }
  }
}

export { UtilData, UtilComputed, UtilMethods }
